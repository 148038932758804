import {
    reqHoardItemList,
    reqTabItemList,
    reqHoardTabItemList,
    reqResourceDetail,
} from "@/api";


const actions = {

    // reqHoardItemList
    async reqHoardItemList() {
        let result = await reqHoardItemList()
        if (result.data.code == 200) {
            return result.data.data;
        }
    },

    // reqTabItemList
    async reqScripturesTabItemList({commit}, formData) {
        let result = await reqTabItemList(formData)
        if (result.data.code == 200) {
            return result.data.data;
        }
    },

    // reqHoardTabItemList
    async reqHoardTabItemList({commit}, formData) {
        let result = await reqHoardTabItemList(formData)
        if (result.data.code == 200) {
            return result.data.data;
        }
    },

    // reqResourceDetail
    async reqResourceDetail({commit}, formData) {
        let result = await reqResourceDetail(formData)
        if (result.data.code == 200) {
            return result.data.data;
        }
    },
};
const mutations = {


};
const state = {

};
const getters = {

}
export default {
    actions,
    mutations,
    state,
    getters,
}