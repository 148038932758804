import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import App from './App.vue'
import router from '@/router'
import store from '@/store'

//引入elementplus icon
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}


import VueLazyload from 'vue-lazyload'

app.use(VueLazyload, {
 preLoad: 1.3,
 error: '/favicon.ico',		//img的加载中的显示的图片的路径
 loading: '/favicon.ico',	//img加载失败时现实的图片的路径
 attempt: 1,					//尝试加载的次数
 listenEvents: ['scroll','wheel','mousewheel','resize','animationend','	transitionend','touchmove'],  //你想让vue监听的事件
})



// 使用use将文件挂载上去
app.use(router).use(store).use(mavonEditor).use(ElementPlus).mount('#app')