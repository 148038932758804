import { 
    reqlingJingItemList,
    reqLingJingTabItemList,
    reqLingJingDetail,
    reqLingJingArticleDetails,
    reqLingJingArticleInfo,
} from "@/api";


const actions = { 
    
    // reqlingJingItemList
    async reqlingJingItemList( ){
        let result = await reqlingJingItemList()
        if (result.data.code == 200){
            return result.data.data;
        }
    },

    // reqLingJingTabItemList
    async reqLingJingTabItemList({commit}, formData){
        let result = await reqLingJingTabItemList(formData)
        if (result.data.code == 200){
            return result.data.data;
        }
    },

    // reqLingJingDetail
    async reqLingJingDetail({commit}, formData){
        let result = await reqLingJingDetail(formData)
        if (result.data.code == 200){
            return result.data.data;
        }
    },

    // reqLingJingArticleDetails
    async reqLingJingArticleDetails({commit}, formData){
        let result = await reqLingJingArticleDetails(formData)
        if (result.data.code == 200){
            return result.data.data;
        }
    },

    // reqLingJingArticleInfo
    async reqLingJingArticleInfo({commit}, formData){
        let result = await reqLingJingArticleInfo(formData)
        if (result.data.code == 200){
            return result.data.data;
        }
    },
};
const mutations = {
   

 };
const state = {
    
 };
const getters = { 
   
}
export default {
    actions,
    mutations,
    state,
    getters,
}