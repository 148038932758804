import { 
    reqFunnyList,
    reqFunnyDayDetail,
} from "@/api";


const actions = { 
    
    // 列表  reqFunnyList
    async reqFunnyList({commit}, formData){
        let result = await reqFunnyList(formData)
        if (result.data.code == 200){
            return result.data.data;
        }
    },

    // reqFunnyDayDetail
    async reqFunnyDayDetail({commit}, formData){
        let result = await reqFunnyDayDetail(formData)
        if (result.data.code == 200){
            return result.data.data;
        }
    },

};
const mutations = {
    

 };
const state = {
   
 };
const getters = { 
    
}
export default {
    actions,
    mutations,
    state,
    getters,
}