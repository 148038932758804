import { 
    reqScripturesItemList,
} from "@/api";


const actions = { 
    
    // reqScripturesItemList
    async reqScripturesItemList( ){
        let result = await reqScripturesItemList()
        if (result.data.code == 200){
            return result.data.data;
        }
    },
};
const mutations = {
   

 };
const state = {
    
 };
const getters = { 
   
}
export default {
    actions,
    mutations,
    state,
    getters,
}