export default [
	//  整体布局页面
	{
	    path: '/',
	    redirect: to => {
			// 检测设备类型
			const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
			// 根据设备类型重定向
			if (isMobile) {
			  return { path: '/hoard' };
			} else {
			  return { path: '/home' };
			}
		  }
	},
	{
		path: '/zqrWebMain',
		name: 'zqrWebMain', 
		component: () => import("@/components/zqrWebMain"),
	},
	{
		path: '/zqrHeader',
		name: 'zqrHeader', 
		component: () => import("@/components/zqrHeader"),
	},
	{
		path: '/goThirdLink',
		name: 'goThirdLink', 
		component: () => import("@/components/goThirdLink"),
	},
	{
		path: '/getResource/:id',
		name: 'getResource', 
		component: () => import("@/components/getResource"),
	},
	{
		path: '/home',
		name: 'home', 
		component: () => import("@/pages/home"),
	},
	{
		path: '/course',
		name: 'course', 
		props: true,
		component: () => import("@/pages/course"),
	},
	{
		path: '/portal',
		name: 'portal', 
		component: () => import("@/pages/portal"),
	},
	{
		path: '/courseMore/:id',
		name: 'courseMore', 
		props: true,
		component: () => import("@/pages/courseMore"),
	},
	{
		path: '/courseDetail/:id',
		name: 'courseDetail', 
		component: () => import("@/pages/courseDetail"),
	},
	{
		path: '/courseContent/:id',
		name: 'courseContent', 
		component: () => import("@/pages/courseContent"),
	},
	{
		path: '/portalMore',
		name: 'portalMore', 
		component: () => import("@/pages/portalMore"),
	},
	{
		path: '/traveler',
		name: 'traveler', 
		component: () => import("@/pages/traveler"),
	},
	{
		path: '/helpMore',
		name: 'helpMore', 
		component: () => import("@/pages/helpMore"),
	},
	{
		path: '/interFlow',
		name: 'interFlow', 
		component: () => import("@/pages/interFlow"),
	},
	{
		path: '/scriptures',
		name: 'scriptures', 
		component: () => import("@/pages/scriptures"),
	},
	{
		path: '/paneMore/:id',
		name: 'paneMore', 
		component: () => import("@/pages/paneMore"),
	},
	{
		path: '/hoard',
		name: 'hoard', 
		component: () => import("@/pages/hoard"),
	},
	{
		path: '/funny',
		name: 'funny', 
		component: () => import("@/pages/funny"),
	},
	{
		path: '/funnyDay/:id',
		name: 'funnyDay', 
		component: () => import("@/pages/funnyDay"),
	},
	{
		path: '/lingjing',
		name: 'lingjing', 
		component: () => import("@/pages/lingjing"),
	},
	{
		path: '/search',
		name: 'search', 
		component: () => import("@/pages/search"),
	},
	{
		path: "/:pathMatch(.*)*", // 必备
		component: () => import("@/pages/404.vue"), 
	  },
]