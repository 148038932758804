import { createStore } from "vuex";


import knowledgeStore from "./knowledgeStore";
import articleStore from "./articleStore";
import navigationMenuStore from "./navigationMenuStore";
import communicationGroupsStore from "./communicationGroupsStore";
import scripturesStore from "./scripturesStore";
import hoardStore from "./hoardStore";
import funnyStore from "./funnyStore";
import lingjingStore from "./lingjingStore";

export default createStore({
    modules: {
        knowledgeStore,
        articleStore,
        navigationMenuStore,
        communicationGroupsStore,
        scripturesStore,
        hoardStore,
        funnyStore,
        lingjingStore,
    }
})