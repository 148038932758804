// 二次封装axios
import axios from "axios";

// 引入进度条
import nprogress from "nprogress";
import 'nprogress/nprogress.css'


// import { getToken } from "@/utils/token";
// 实例化request
const requests = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 20000,
})

// 请求拦截器
requests.interceptors.request.use((config) => {
    // if (getToken()) {
    //     config.headers.token = getToken()
    // }
    nprogress.start();
    return config;
})

// 响应拦截器
requests.interceptors.response.use((config) => {
    nprogress.done();
    return config
}, (error) => {
    return Promise.reject(new Error("failed"))
})

export default requests;