import { 
    reqNavigationItemList,
    reqNavigationItemByMenuId
} from "@/api";


const actions = { 
    
    // 实例  reqNavigationItemList
    async reqNavigationItemList({commit}, formData){
        let result = await reqNavigationItemList( )
        if (result.data.code == 200){
            return result.data.data;
        }
    },

    // 通过菜单id查询  reqNavigationItemByMenuId
    async reqNavigationItemByMenuId({commit}, formData){
        let result = await reqNavigationItemByMenuId( formData)
        if (result.data.code == 200){
            return result.data.data;
        }
    },
};
const mutations = {
    

 };
const state = {
   
 };
const getters = { 
    
}
export default {
    actions,
    mutations,
    state,
    getters,
}