<template>
    <zqrWebMain />
</template>

<script>
import zqrWebMain from "@/components/zqrWebMain.vue";

export default {
  name: "App",
  components: {
    zqrWebMain,
  },
};
</script>

<style>
body{
  margin: 0px;
  padding: 0px;
}

#app {
  margin: 0px;
  padding: 0px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  font:  normal 1.4rem "楷体", "KaiTi", Arial, "Helvetica Neue", Helvetica, sans-serif;
  background-color: #f7f7f7;
}

a {
  text-decoration: none; /* 去除下划线 */
  color: inherit; /* 继承父元素的颜色 */
}
</style>
