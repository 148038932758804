import { 
    reqKnowledgeAllList,
    reqKnowledgeDetail,
    reqKnowledgeByColumnList
} from "@/api";


const actions = { 
    
    // 课程列表  reqKnowledgeAllList
    async reqKnowledgeAllList({}){
        let result = await reqKnowledgeAllList()
        if (result.data.code == 200){
            return result.data.data;
        }
    },
    
    // 课程详情  reqKnowledgeDetail
    async reqKnowledgeDetail({commit}, formData){
        let result = await reqKnowledgeDetail("columnId=" + formData)
        if (result.data.code == 200){
            return result.data.data;
        }
    },

    // 通过专栏查询课程列表 reqKnowledgeByColumnList
    async reqKnowledgeByColumnList({commit}, formData){
        let result = await reqKnowledgeByColumnList(formData)
        if (result.data.code == 200){
            return result.data.data;
        }
    },

};
const mutations = {
    

 };
const state = {
   
 };
const getters = { 
    
}
export default {
    actions,
    mutations,
    state,
    getters,
}