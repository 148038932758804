<template >
  <div>
    <el-container>
      <el-header>
        <zqrHeader></zqrHeader>
      </el-header>

      <el-main>
        <el-scrollbar>
          <!-- 路由页面 -->
          <!-- <keep-alive> -->
          <!-- <transition mode="out-in" appear> -->
          <router-view> </router-view>
          <!-- </transition> -->
          <!-- </keep-alive> -->
          <!-- <router-view v-slot="{ Component }">
            <keep-alive>
              <component :is="Component" />
            </keep-alive>
          </router-view> -->
        </el-scrollbar>
      </el-main>
    </el-container>
  </div>
</template>
  
  <script>
import zqrFooter from "@/components/zqrFooter.vue";
import zqrHeader from "@/components/zqrHeader.vue";
export default {
  name: "zqrWebMain",
  components: {
    zqrHeader,
    zqrFooter,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
  
  <style>
::-webkit-scrollbar {
  display: none;
}
.el-header {
  height: 3.7rem;
  --el-header-padding: 0rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.el-main {
  padding: 0;
  height: calc(100vh - 3.7rem);
}
</style>
  