<template>
  <el-footer>
    <div class="footer-div">
      <p class="footer-span">
        Copyright © 2024 山海同行 shanhaigo.cn 版权所有
        <a href="http://beian.miit.gov.cn">京ICP备2021040070号-3</a>
        京ICP备2021040070号
      </p>
    </div>
  </el-footer>
</template>
<script>
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?b4c3d65e0191b894799e96437fa3ee48";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();

export default {
  name: "zqrFooter",
  data() {
    return {};
  },
};
</script>

<style scoped>
.el-footer {
  padding: 0%;
  margin: 0%;
  width: 100%;
  --el-footer-height: 2rem;
}
.footer-div {
  font-size: 1rem;
  line-height: 1rem;
  color: #000;
  text-align: center;
  align-items: center; /* 垂直居中 */
  /* background-color: white; */
}

@media screen and (max-width: 100rem) {
  .footer-div {
    font-size: 0.4rem;
  }
}
</style>