import {createRouter, createWebHistory} from 'vue-router'
import routes from "./routes"

// 创建路由器
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})


export default router;
