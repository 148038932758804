import { 
    reqArticleDetails,
    reqArticleInfoList,
    reqArticleHelpList,
} from "@/api";


const actions = { 
    
    // 文章详情 reqArticleDetails
    async reqArticleDetails({commit}, formData){
        let result = await reqArticleDetails(formData)
        if (result.data.code == 200){
            return result.data.data;
        }
    },

    // 文章目录 reqArticleInfoList
    async reqArticleInfoList({commit}, formData){
        let result = await reqArticleInfoList(formData)
        if (result.data.code == 200){
            return result.data.data;
        }
    },

    // 获取帮助列表 reqArticleHelpList
    async reqArticleHelpList({commit}, formData){
        let result = await reqArticleHelpList(formData)
        if (result.data.code == 200){
            return result.data.data;
        }
    },
};
const mutations = {
   

 };
const state = {
    
 };
const getters = { 
   
}
export default {
    actions,
    mutations,
    state,
    getters,
}